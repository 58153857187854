<template>
    <ExamHeader @logout="logout" @changeExam="refreshToken" />
    <div v-if="exam.Loading" class="text-center">
        <div class="text-2xl">Loading Exam</div>
        <AbogLoading class="w-40"></AbogLoading>
    </div>
    <div v-else-if="exam.Failed" class="text-center">
        <div class="text-2xl">Error Loading Exam</div>
        <p>Please reload to try again. If errors persist contact your exam administrator.</p>
    </div>
    <RouterView v-else v-on="routeSpecificEventListeners" />
</template>
<script setup>
import { ref, computed, onMounted, inject, onUnmounted } from "vue";
import { useRouter } from "vue-router";
import { useExamStore } from "@/pages/components/ExamStore.js";
import AbogLoading from "@/pages/components/AbogLoading";
import { WebPubSubClient } from "@azure/web-pubsub-client";
import AbogAlert from "@/pages/components/AbogAlert";
// import AbogModal from "@/pages/components/AbogModal";
import ExamHeader from "@/pages/components/ExamHeader";
import Token from "@/token";
// inject globals
const axios = inject("axios");
const router = useRouter();
const exam = useExamStore();
// data
const showTimeout = ref(false);
const routeSpecificEventListeners = computed(() => {
    // this prevents warnings for undeclared emits, only look for events on the routes we care about
    if (router.currentRoute.value.name === "Login" || router.currentRoute.value.name === "SetToken") return { login: loginSetup };
    return {};
});
let warntoid = null;
// created
onMounted(() => {
    // initialize if we have a token already
    const payload = Token.payload();
    if (payload) {
        const jwt = Token.get();
        axios.defaults.headers.common["Authorization"] = "Bearer " + jwt;
        exam.refresh();
        startRefreshTimer();
        connectWebSocket();
    }
});
onUnmounted(() => {
    closeWebSocket();
});
// methods
function logout() {
    stopRefreshTimer();
    router.push("/login");
}
function loginSetup() {
    startRefreshTimer();
    connectWebSocket();
}
function startRefreshTimer() {
    const fiveminutes = 1000 * 60 * 5;
    const payload = Token.payload();
    if (payload) {
        const timetoexp = new Date(payload.exp * 1000) - new Date();
        const waittime = timetoexp - fiveminutes;
        if (waittime < 10000) {
            refreshToken();
        } else {
            warntoid = window.setTimeout(() => {
                startRefreshTimer();
            }, waittime);
        }
    }
}
function refreshToken(examname) {
    const authurl = examname ? `authentication?exam=${examname}` : "authentication";
    axios
        .get(authurl)
        .then((response) => {
            Token.set(response.data);
            axios.defaults.headers.common["Authorization"] = "Bearer " + response.data;
            startRefreshTimer();
            if (examname) {
                // changed exam, so reload and go back to root
                exam.refresh();
                router.push("/");
            }
        })
        .catch(() => {
            // auth failed
            router.push({ name: "Login", query: { auth: "true" } });
        });
}
function stopRefreshTimer() {
    window.clearTimeout(warntoid);
    showTimeout.value = false;
}

const appClient = new WebPubSubClient({
    getClientAccessUrl: async () => {
        return (await axios.get("authentication/pubsub")).data;
    },
});

function connectWebSocket() {
    if (Token.payload().role == "OrgAdmin") {
        appClient.start();
        appClient.on("group-message", (event) => {
            const wsdata = event.message.data;
            if (wsdata.command === "message") {
                AbogAlert.error(`${wsdata.FullName} needs help in ${wsdata.room} Exam:${wsdata.exam}`);
            }
            if (wsdata.command === "canceled") {
                AbogAlert.error(`${wsdata.FullName} has canceled their request Exam:${wsdata.exam}`);
            }
        });
        appClient.joinGroup("admin");
    }
}
function closeWebSocket() {
    appClient.stop();
}
</script>
<style src="@/css/includes.css"></style>
<style src="@/css/common.css"></style>
